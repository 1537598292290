import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CFormLabel, CFormInput, CFormFloating, CButton } from "@coreui/react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "./api";
import 'moment/locale/it';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import GlobalSnackbar from "./Snackbar";
import moment from "moment";
import ReusableButton from "./Reusable/Buttons";

const DettaglioCosti = () => {
  const [costi, setCosti] = useState({
    utente: localStorage.getItem("nomeUtente"),
    data: "",
    cantiere: null,
    wbs: null,
    tipologia_costo: null,
    quantita: "",
    importo: "",
    totale: "",
  });

  const [cantieriSel, setCantieriSel] = useState(null);
  const [wbs, setWbs] = useState([]);
  const [webSel, setWbsSel] = useState(null);
  const [tipo, setTipo] = useState([]);
  const [tipoSel, setTipoSel] = useState(null);
  const [cantieri, setCantieri] = useState([]);
  const [voce, setVoce] = useState([]);
  const [searchReady, setSearchReady] = useState(false);
  const [options, setOptions] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });
  const handleChange = (event) => {
    let cos = { ...costi };
    cos[event.target.name] = event.target.value;
    cos.totale = cos?.importo * cos?.quantita;
    setCosti(cos);
  };
  const handleDeleteButton = async () => {
    let isExecuted = window.confirm("Sei sicuro di voler cancellare la Costi?");
    if (isExecuted) {
      await api.delete("/api/costi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          id: costi.id,
        },
      });
      setSnack({
        severity: "success",
        messaggio: "Costi eliminata con successo",
      });
      setOpenSnack(true);
      //alert("Costi eliminata con successo");
      //navigate(-1);
    }
  };
  const handleConfirmButton = async () => {
    const cos = { ...costi };
    cos["utente"] = localStorage.getItem("nomeUtente");
    if (location.state.operazione === "Nuovo") {
      const response = await api.post(
        "api/costi",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", costi: cos },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      location.state.operazione = "Modifica";
      cos["id"] = response.data.message.insertId; //ottengo l'id dell'elemento appena inserito
      setSnack({ severity: "success", messaggio: "Inserimento effettuato" });
      setOpenSnack(true);
      //alert("Inserimento effettuato");
    } else {
      let cosDataCorretta = { ...cos };
      cosDataCorretta["data"] = moment(cos.data).format("YYYY-MM-DD");
      await api.put(
        "api/costi",
        { auth: "a5d42c3ed7977afa2d8e922569e7940b", costi: cosDataCorretta },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
      setSnack({ severity: "success", messaggio: "Modifica effettuata" });
      setOpenSnack(true);
    }
  };

  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      const resCant = await api.get("api/cantieri", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });

      const cantieri =
        resCant.data.data; /* .map((cantiere) => cantiere.commessa) */
      setCantieri(cantieri);
      setCantieriSel(
        location.state.operazione === "Nuovo"
          ? null
          : cantieri?.filter(
              (el) => el.commessa === location.state.costi?.cantiere
            )[0]
      );

      if (location.state.operazione === "Nuovo") {
        const cos = { ...costi };
        cos["data"] = moment(new Date());
        setCosti(cos);
      } else {
        const costi = location.state.costi;
        setCosti(costi);
      }

      const tipo = await api.get("api/tipologia_costi", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setTipo(tipo.data.data);
      setTipoSel(
        location.state.operazione === "Nuovo"
          ? null
          : tipo.data.data?.filter(
              (el) => el.id === location.state.costi?.tipologia_costo
            )[0]
      );

      const wbs = await api.get("api/cantieri_wbs", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      });
      setWbsSel(
        location.state.operazione === "Nuovo"
          ? null
          : wbs.data.data?.filter(
              (el) => el.id === location.state.costi?.wbs
            )[0]
      );
      setWbs(wbs.data.data);
      setSearchReady(true);
    };
    fetchData();
  }, []);

  return (
    <>
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <div className="mt-5 pt-5 p-md-5 p-0">
        {/* CARD CONTAINER */}
        <div className="card text-center  border-classic">
          {/* HEADER */}
          <div className="card-header position-relative">
            <span className="fs-4 col">Inserimento Costi</span>
            <span className="position-absolute top-50 end-0 translate-middle-y mr-3">
              {" "}
            </span>{" "}
            <a
              style={{ cursor: "pointer", color: "black" }}
              className="position-absolute top-50 end-0 translate-middle-y indigo-500"
              onClick={() => setOptions(!options)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="30"
                fill="currentColor"
                className="bi bi-three-dots-vertical pr-3"
                viewBox="0 0 16 16"
              >
                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
              </svg>
            </a>
            <ul
              className={`dropdown-menu position-absolute top-200 end-0 ${
                options ? "show" : ""
              }`}
            >
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => {
                    navigate(`/home/macchinari/stampa/${costi.id}`, {
                      state: { costi: costi, stampa: "CostioDettaglio" },
                    });
                  }}
                >
                  <i className="bi bi-printer"></i> STAMPA
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  style={{
                    display:
                      JSON.parse(localStorage.getItem("permission")).costi
                        .allowDelete === 0
                        ? "none"
                        : "block",
                  }}
                  onClick={() => {
                    handleDeleteButton();
                  }}
                >
                  <i className="bi bi-trash"></i> ELIMINA
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  //onClick={() =>{}}
                  //style={{}}
                >
                  <i className="bi bi-plus-circle"></i> AGGIUNGI
                </a>
              </li>
            </ul>
          </div>

          {/* BODY */}
          <div className="card-body">
            <div className="row p-4">
              <div className="col-6 mb-3">
                <CFormFloating>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    locale='it'
                  >
                    <DatePicker
                      label="Data"
                      value={moment(costi.data)}
                      renderInput={(params) => (
                        <TextField {...params} className="col-12" />
                      )}
                      onChange={(newValue) => {
                        let prod = { ...costi };
                        prod["data"] = moment(newValue)
                          .local()
                          .format("YYYY-MM-DD");
                        setCosti(prod);
                      }}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).costi
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                  </LocalizationProvider>
                </CFormFloating>
              </div>
              <div className="col-6 d-flex flex-nowrap">
                <div className="mb-3 col-10">
                  <Autocomplete
                    value={cantieriSel}
                    onChange={(event, newValue) => {
                      const cos = { ...costi };
                      cos.cantiere = newValue?.commessa;
                      setCosti(cos);
                      setCantieriSel(newValue);
                    }}
                    getRowId={(row) => row.id}
                    options={cantieri}
                    getOptionLabel={(option) => `${option.commessa}`}
                    renderInput={(params) => (
                      <TextField {...params} label="Commessa" />
                    )}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).costi
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                </div>
                <CButton
                  className="btn btn-green mb-3 col-2"
                  style={{
                    display:
                      sessionStorage.getItem("SESSION") === "user" ||
                      localStorage.getItem("LOCAL") === "user"
                        ? "none"
                        : "block",
                  }}
                  onClick={() =>
                    navigate(`/home/cantieri/dettaglio-cantiere`, {
                      state: { cantiere: [], operazione: "Nuovo" },
                    })
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-plus-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                  </svg>
                </CButton>
              </div>
              <div className="mb-3 col-6">
                <Autocomplete
                  value={webSel}
                  onChange={(event, newValue) => {
                    const cos = { ...costi };
                    cos.wbs = newValue?.id;
                    setCosti(cos);
                    setWbsSel(newValue);
                  }}
                  getRowId={(row) => row.id}
                  options={wbs}
                  getOptionLabel={(option) => `${option.descrizione}`}
                  renderInput={(params) => (
                    <TextField {...params} label="Wbs" />
                  )}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).costi
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
              </div>
              <div className="mb-3 col-6">
                <Autocomplete
                  value={tipoSel}
                  onChange={(event, newValue) => {
                    const cos = { ...costi };
                    cos.tipologia_costo = newValue?.id;
                    setTipoSel(newValue);
                    setCosti(cos);
                  }}
                  getRowId={(row) => row.id}
                  options={tipo}
                  getOptionLabel={(option) => `${option.descrizione}`}
                  renderInput={(params) => (
                    <TextField {...params} label="Tipo" />
                  )}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).costi
                      .allowEdit === 0
                      ? true
                      : false
                  }
                />
              </div>
              <div className="mb-3 col-4">
                <CFormFloating>
                  <CFormInput
                    type="number"
                    name="quantita"
                    defaultValue={costi.quantita}
                    onChange={handleChange}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).costi
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormLabel>Quantità</CFormLabel>
                </CFormFloating>
              </div>
              <div className="mb-3 col-4 p-0">
                <CFormFloating>
                  <CFormInput
                    type="number"
                    name="importo"
                    defaultValue={costi.importo}
                    onChange={handleChange}
                    disabled={
                      JSON.parse(localStorage.getItem("permission")).costi
                        .allowEdit === 0
                        ? true
                        : false
                    }
                  />
                  <CFormLabel>Importo</CFormLabel>
                </CFormFloating>
              </div>
              <div className="mb-3 col-4">
                <CFormFloating>
                  <CFormInput
                    type="number"
                    name="totale"
                    defaultValue={costi.totale}
                    disabled={true}
                  />
                  <CFormLabel>Totale</CFormLabel>
                </CFormFloating>
              </div>
              {/* <div className="row d-flex no-wrap">
                  
                  <div className="col-12 col-md-6 ">
                    <h4>Fornitore</h4>
                    <div className="row d-flex flex-nowrap gx-0">
                      <CFormFloating
                        className="mb-3 col-10 col-md-10"
                        style={{
                          height: "100%",
                          width:
                            sessionStorage.getItem("SESSION") === "user" ||
                            localStorage.getItem("LOCAL") === "user"
                              ? "100%"
                              : "",
                        }}
                      >
                        {searchReady ? (
                          <Autocomplete
                            value={costi.voce}
                            onChange={(event, newValue) => {
                              const costi = { ...costi };
                              costi["voce"] = newValue;
                              setCosti(costi);
                            }}
                            getRowId={(row) => row.id}
                            options={voce}
                            getOptionLabel={(option) => `${option}`}
                            renderInput={(params) => (
                              <TextField {...params} label="Voce" />
                            )}
                            disabled={true}
                          />
                        ) : (
                          ""
                        )}
                      </CFormFloating>
                      <CButton
                        className="btn btn-green mb-3 col-2 col-md-2"
                        disabled={true}
                        style={{
                          display:
                            sessionStorage.getItem("SESSION") === "user" ||
                            localStorage.getItem("LOCAL") === "user"
                              ? "none"
                              : "block",
                        }}
                        onClick={() =>
                          navigate(`/home/cantieri/dettaglio-cantiere`, {
                            state: { cantiere: [], operazione: "Nuovo" },
                          })
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-plus-circle"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                      </CButton>
                    </div>
                    <CFormLabel className="col-6 col-md-6">
                      <b>Fornitore1</b>
                    </CFormLabel>
                    <CFormLabel className="col-6 col-md-6">
                      {costi.fornitore1}
                    </CFormLabel>

                    <CFormLabel className="col-6 col-md-6">
                      <b>Prezzo</b>
                    </CFormLabel>
                    <CFormLabel className="col-6 col-md-6">
                      {costi.prezzo}
                    </CFormLabel>

                    <CFormFloating className="col-6 d-flex justify-content-sm-start align-items-center">
                      <CFormSwitch
                        label="Prezzo nd"
                        className="mb-3"
                        value={costi.prezzo_nd === "1" ? true : false}
                        id="prezzo_nd"
                        onChange={() => handleChangeRadio()}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).costi
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                    </CFormFloating>
                    <CFormFloating className="mb-3 col-12">
                      <CFormInput
                        type="text"
                        id="MatricolaInput"
                        name="quantita"
                        defaultValue={costi.quantita}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).costi
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Quantità</CFormLabel>
                    </CFormFloating>

                    <CFormLabel className="col-6 col-md-6">
                      <b>Costo</b>{" "}
                    </CFormLabel>
                    <CFormLabel className="col-6 col-md-6">
                      {costi.costo} €
                    </CFormLabel>
                  </div>
                  <div className="col-12 col-md-6 mb-4">
                    <h4>Personale</h4>
                    <CFormFloating className="mb-3 col-12">
                      <CFormInput
                        type="text"
                        id="MatricolaInput"
                        name="personale_ore1"
                        defaultValue={costi.personale_ore1}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).costi
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Ore Personale</CFormLabel>
                    </CFormFloating>
                    <CFormLabel className="col-6 col-md-6">
                      <b>Costo orario personale</b>{" "}
                    </CFormLabel>
                    <CFormLabel className="col-6 col-md-6">
                      {costi.personale_ore1prezzo}
                    </CFormLabel>
                    <CFormFloating className="mb-3 col-12">
                      <CFormInput
                        type="text"
                        id="MatricolaInput"
                        name="personale_pasti1"
                        defaultValue={costi.personale_pasti1}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).costi
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Pasti</CFormLabel>
                    </CFormFloating>
                    <CFormFloating className="mb-3 col-12">
                      <CFormInput
                        type="text"
                        id="MatricolaInput"
                        name="personale_alloggi1"
                        defaultValue={costi.personale_alloggi1}
                        onChange={handleChange}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).costi
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                      <CFormLabel>Alloggi</CFormLabel>
                    </CFormFloating>
                  </div>
                </div> */}
            </div>
          </div>

          {/* FOOTER */}
          <div className="card-footer d-flex justify-content-between">
            <div className="w-25 d-flex justify-content-start">
              <ReusableButton
                text="CHIUDI"
                icon="x"
                size="xs"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>

            <div className="w-25 d-flex justify-content-end">
              <ReusableButton
                text="SALVA"
                icon="check"
                size="xs"
                style={{
                  display:
                    JSON.parse(localStorage.getItem("permission")).costi
                      .allowEdit === 0
                      ? "none"
                      : "block",
                }}
                onClick={() => handleConfirmButton()}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DettaglioCosti;
