import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./api";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CButton,
  CRow,
} from "@coreui/react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import QRCode from "qrcode";


import moment from "moment";
import { isLogged } from "./SafeRoute";

const AtterraggioPersonaleQrcode = () => {
  const log = isLogged();

  const [personale, setPersonale] = useState({
    matricola: "",
    nome: "",
    cognome: "",
    mansione: null,
    luogo: "",
    data_inizio: null,
    data_fine: null,
    data_nascita: null,
    foto: "",
    documenti_ce: "",
    ci: "",
    scadenza_ci: null,
    psog: "",
    scadenza_psog: null,
    cf: "",
    scadenza_cf: null,
    ts: "",
    scadenza_ts: null,
    patente: "",
    scadenza_patente: null,
    unilav: "",
    scadenza_unilav: null,
    idoneita: "",
    scadenza_idoneita: null,
    formazione: "",
    scadenza_formazione: null,
    preposto: "",
    scadenza_preposto: null,
    dirigente: "",
    scadenza_dirigente: null,
    rspp: "",
    scadenza_rspp: null,
    rls: "",
    scadenza_rls: null,
    diisocianati: "",
    scadenza_diisocianati: null,
    ps: "",
    scadenza_ps: null,
    antincendio: "",
    scadenza_antincendio: null,
    dpi3cat: "",
    scadenza_dpi3cat: null,
    saldatore: "",
    scadenza_saldatore: null,
    ponteggi: "",
    scadenza_ponteggi: null,
    quota: "",
    scadenza_quota: null,
    ambconf: "",
    scadenza_ambconf: null,
    dumper: "",
    scadenza_dumper: null,
    pfr_pd: "",
    scadenza_pfr_pd: null,
    pfr_gd: "",
    scadenza_pfr_gd: null,
    ple: "",
    scadenza_ple: null,
    carroponte: "",
    scadenza_carroponte: null,
    pompecls: "",
    scadenza_pompecls: null,
    grutorre: "",
    scadenza_grutorre: null,
    elev: "",
    scadenza_elev: null,
    autogru: "",
    scadenza_autogru: null,
    mmt: "",
    scadenza_mmt: null,
    altro1: "",
    scadenza_altro1: null,
    altro2: "",
    scadenza_altro2: null,
    limitazione: "",
    prescrizione: "",
  });

  const [valueT, setValue] = useState(1);

  const [visibile, setVisibile] = useState({
    ci: false,
    psog: false,
    cf: false,
    ts: false,
    patente: false,
    unilav: false,
    idoneita: false,
    formazione: false,
    preposto: false,
    dirigente: false,
    rspp: false,
    rls: false,
    diisocianati: false,
    ps: false,
    antincendio: false,
    dpi3cat: false,
    saldatore: false,
    ponteggi: false,
    quota: false,
    ambconf: false,
    dumper: false,
    pfr_pd: false,
    pfr_gd: false,
    ple: false,
    carroponte: false,
    pompecls: false,
    grutorre: false,
    elev: false,
    autogru: false,
    mmt: false,
    altro1: false,
    altro2: false,
    limitazione: false,
    prescrizione: false,
  });

  const [qrcode, setQrcode] = useState("");
  const [circolazione, setCircolazione] = useState(false);
  const [assicurazione, setAssicurazione] = useState(false);
  const [funiCatene, setFuniCatene] = useState(false);
  const [verificaPeriodica, setVerificaPeriodica] = useState(false);
  const [ready, setReady] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const displayNone = () => {
    if (
      sessionStorage.getItem("SESSION") === "user" ||
      localStorage.getItem("LOCAL") === "user"
    ) {
      return "none";
    } else {
      return "block";
    }
  };

  const handleChangeValue = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      let MatricolaUrl = location.pathname.split("/");
      let macchinaTest = "";
      if (log) {
        await api
          .get("api/qrcode", {
            params: { id: MatricolaUrl[MatricolaUrl.length - 1] },
            /* body: { auth: "a5d42c3ed7977afa2d8e922569e7940b" }, */
          })
          .then(async (res) => {
            const personale = res.data.data;
            //passaggio dati Documenti Scadenze
            scadenze(personale[0]);
            //chiamata await funzione caricamento IMG
            caricamentoFoto(personale[0]);
            setPersonale(personale[0]);
            macchinaTest = personale[0];
            setReady(true);
            let url = `https://react.millergroup.it/home/macchinari/qrcode/${personale.matricola}`;
            QRCode.toDataURL(url).then((data) => {
              setQrcode(data);
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        navigate("/login-qr", {
          state: {
            link: "personale",
            id: MatricolaUrl[MatricolaUrl.length - 1],
          },
        });
      }
    };
    //caricamentoFoto(personale);
    fetchData();
  }, []);
  const caricamentoFoto = async (personale) => {
    if (
      personale.foto !== "" ||
      personale.foto !== null ||
      personale.foto !== undefined
    ) {
      let f = personale.foto.split("/");
      let file = f[2];

      await api.post(
        "api/ftp",
        {
          file: file,
          percorso: personale.foto,
          operazione: "prendi",
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      );
    }
  };
  const scadenze = (personale) => {
    personale.scadenza_circolazione !== null
      ? setCircolazione(true)
      : setCircolazione(false);
    personale.scadenza_assicurazione !== null
      ? setAssicurazione(true)
      : setAssicurazione(false);
    personale.scadenza_varie2 !== null
      ? setFuniCatene(true)
      : setFuniCatene(false);
    personale.scadenza_varie3 !== null
      ? setVerificaPeriodica(true)
      : setVerificaPeriodica(false);
  };

  const manageFiles = async (cFile) => {
    let f = cFile.split("/");
    let file = f[2];

    await api
      .post(
        "api/ftp",
        { file: file, percorso: cFile, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + file);
      });
  };
  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <div className="card text-center  border-classic mx-4 mx-md-0">
        <div className="card-header position-relative">
          <span className="fs-4 col">
            {personale.matricola}_{personale.cognome}_{personale.nome}
          </span>
          <span
            className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <i className="bi bi-x fs-1 my-auto"></i>
          </span>
        </div>
        <div className="card-body">
          <TabContext value={valueT}>
            <div className="card-header bg-light d-flex">
              <TabList
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  width: "100%",
                }}
                onChange={handleChangeValue}
              >
                <Tab
                  icon={
                    <>
                      <i className="bi bi-clipboard-data fs-5"></i>
                      <span className="fs-5 text-wrap bg-light text-decoration-none pe-auto">
                        DETTAGLI
                      </span>
                    </>
                  }
                  className="row justify-content-center"
                  value={1}
                ></Tab>
                <Tab
                  icon={
                    <>
                      <i className="bi bi-file-earmark-pdf fs-5"></i>
                      <span className="fs-5 text-wrap bg-light text-decoration-none pe-auto">
                        DOCUMENTI
                      </span>
                    </>
                  }
                  className="row justify-content-center"
                  value={2}
                ></Tab>
              </TabList>
            </div>
            <div className="card-body">
              <TabPanel value={1}>
                <div className="row d-flex justify-content-center">
                  <div className="w-100 d-flex row flex-wrap mb-3 bg-cover bg-center">
                    {personale.foto ? (
                      <>
                        <div
                          className="px-0 personale-image"
                          style={{
                            background: `url(${
                              personale.foto
                                ? "https://portale.fondamentasrl.it/file?file=" +
                                  personale.foto?.split("/")[2]
                                : ""
                            }) no-repeat center / contain transparent`,
                          }}
                        ></div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>

                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="text"
                      defaultValue={personale.nome}
                      disabled
                    />
                    <CFormLabel>Nome</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="text"
                      defaultValue={personale.cognome}
                      disabled
                    />
                    <CFormLabel>Cognome</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="text"
                      defaultValue={personale.luogo}
                      disabled
                    />
                    <CFormLabel>Luogo</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="data"
                      defaultValue={
                        personale.data_nascita
                          ? moment(personale.data_nascita).format("YYYY-MM-DD")
                          : "YYYY-MM-DD"
                      }
                      disabled
                    />
                    <CFormLabel>Data di Nascita</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="data"
                      defaultValue={
                        personale.data_nascita
                          ? moment(personale.data_inizio).format("YYYY-MM-DD")
                          : "YYYY-MM-DD"
                      }
                      disabled
                    />
                    <CFormLabel>Data Assunzione</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="text"
                      defaultValue={personale.descrizione}
                      disabled
                    />
                    <CFormLabel>Mansione</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 px-2">
                    <CFormInput
                      type="data"
                      defaultValue={
                        personale.data_nascita
                          ? moment(personale.data_fine).format("YYYY-MM-DD")
                          : "YYYY-MM-DD"
                      }
                      disabled
                    />
                    <CFormLabel>Data Termine</CFormLabel>
                  </CFormFloating>
                  <div className="mb-3 row">
                    <div className="col-12" id="qrcode">
                      <img src={qrcode} className="img-thumbnail" alt="..." />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value={2}>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Carta di Identità
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.ci)}
                      style={{
                        display: personale.ci ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Permesso di Soggiorno
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.psog)}
                      style={{
                        display: personale.psog ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Codice Fiscale
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.cf)}
                      style={{
                        display: personale.cf ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Tessera Sanitaria
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.ts)}
                      style={{
                        display: personale.ts ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Patente
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.patente)}
                      style={{
                        display: personale.patente ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">Unilav</CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.unilav)}
                      style={{
                        display: personale.unilav ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Idoneita
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.idoneita)}
                      style={{
                        display: personale.idoneita ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Prescrizione
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.prescrizione)}
                      style={{
                        display: personale.prescrizione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Limitazione
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.limitazione)}
                      style={{
                        display: personale.limitazione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Corso Base
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.formazione)}
                      style={{
                        display: personale.formazione ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Corso Preposto
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.preposto)}
                      style={{
                        display: personale.preposto ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Corso Dirigente
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.dirigente)}
                      style={{
                        display: personale.dirigente ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Corso RSPP
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.rspp)}
                      style={{
                        display: personale.rspp ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Corso RLS
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.rls)}
                      style={{
                        display: personale.rls ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Corso Diisocianati
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.diisocianati)}
                      style={{
                        display: personale.diisocianati ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Primo Soccorso
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.ps)}
                      style={{
                        display: personale.ps ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Antincendio
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.antincendio)}
                      style={{
                        display: personale.antincendio ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      DPI 3^ CAT
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.dpi3cat)}
                      style={{
                        display: personale.dpi3cat ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Saldatore
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.saldatore)}
                      style={{
                        display: personale.saldatore ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Ponteggi
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.ponteggi)}
                      style={{
                        display: personale.ponteggi ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Lavori in Quota
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.quota)}
                      style={{
                        display: personale.quota ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Confinanti
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.ambconf)}
                      style={{
                        display: personale.ambconf ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">Dumper</CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.dumper)}
                      style={{
                        display: personale.dumper ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Perforatore Piccolo Diametro
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.pfr_pd)}
                      style={{
                        display: personale.pfr_pd ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Perforatore Grande Diametro
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.pfr_gd)}
                      style={{
                        display: personale.pfr_gd ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">PLE</CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.ple)}
                      style={{
                        display: personale.ple ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Carroponte
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.carroponte)}
                      style={{
                        display: personale.carroponte ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Pompe CLS
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.pompecls)}
                      style={{
                        display: personale.pompecls ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Gru a Torre
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.grutorre)}
                      style={{
                        display: personale.grutorre ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Carrelli Elevatori
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.elev)}
                      style={{
                        display: personale.elev ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Autogru
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.autogru)}
                      style={{
                        display: personale.autogru ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">
                      Macchine Movimento Terra
                    </CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.mmt)}
                      style={{
                        display: personale.mmt ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">Altro</CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.altro1)}
                      style={{
                        display: personale.altro1 ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
                <CRow className="mb-3 d-flex  align-items-center">
                  <div className="d-flex col-md-6 col-12  align-items-center">
                    <CFormLabel className="col-6 text-start">Altro</CFormLabel>
                    <CButton
                      size="sm"
                      className="col-3 btn btn-secondary rounded"
                      onClick={() => manageFiles(personale.altro2)}
                      style={{
                        display: personale.altro2 ? "block" : "none",
                      }}
                    >
                      <i className="bi bi-download"></i>
                    </CButton>
                  </div>
                </CRow>
              </TabPanel>
            </div>
          </TabContext>
        </div>
      </div>
    </div>
  );
};

export default AtterraggioPersonaleQrcode;
