import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import api from "./api";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  itIT,
} from "@mui/x-data-grid-pro";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CFormLabel,
  CFormInput,
  CFormFloating,
  CButton,
  CCollapse,
  CCol,
  CFormSwitch,
} from "@coreui/react";
import Tab from "@mui/material/Tab";
import TextField from "@mui/material/TextField";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import 'moment/locale/it';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import moment from "moment";
import ReusableButton from "./Reusable/Buttons";

import GlobalSnackbar from "./Snackbar";
import { Backdrop, CircularProgress } from "@mui/material";

import { saveAs } from "file-saver";

const DettaglioPersonale = () => {
  const [personale, setPersonale] = useState({
    matricola: "",
    nome: "",
    cognome: "",
    mansione: null,
    luogo: "",
    data_inizio: null,
    data_fine: null,
    data_nascita: null,
    foto: "",
    documenti_ce: "",
    ci: "",
    scadenza_ci: null,
    psog: "",
    scadenza_psog: null,
    cf: "",
    scadenza_cf: null,
    ts: "",
    scadenza_ts: null,
    patente: "",
    scadenza_patente: null,
    unilav: "",
    scadenza_unilav: null,
    idoneita: "",
    scadenza_idoneita: null,
    formazione: "",
    scadenza_formazione: null,
    preposto: "",
    scadenza_preposto: null,
    dirigente: "",
    scadenza_dirigente: null,
    rspp: "",
    scadenza_rspp: null,
    rls: "",
    scadenza_rls: null,
    diisocianati: "",
    scadenza_diisocianati: null,
    ps: "",
    scadenza_ps: null,
    antincendio: "",
    scadenza_antincendio: null,
    dpi3cat: "",
    scadenza_dpi3cat: null,
    saldatore: "",
    scadenza_saldatore: null,
    ponteggi: "",
    scadenza_ponteggi: null,
    quota: "",
    scadenza_quota: null,
    ambconf: "",
    scadenza_ambconf: null,
    dumper: "",
    scadenza_dumper: null,
    pfr_pd: "",
    scadenza_pfr_pd: null,
    pfr_gd: "",
    scadenza_pfr_gd: null,
    ple: "",
    scadenza_ple: null,
    carroponte: "",
    scadenza_carroponte: null,
    pompecls: "",
    scadenza_pompecls: null,
    grutorre: "",
    scadenza_grutorre: null,
    elev: "",
    scadenza_elev: null,
    autogru: "",
    scadenza_autogru: null,
    mmt: "",
    scadenza_mmt: null,
    altro1: "",
    scadenza_altro1: null,
    altro2: "",
    scadenza_altro2: null,
    limitazione: "",
    prescrizione: "",
  });
  const [personaleBackup, setPersonaleBackup] = useState({
    matricola: "",
    nome: "",
    cognome: "",
    mansione: null,
    luogo: "",
    data_inizio: null,
    data_fine: null,
    data_nascita: null,
    foto: "",
    documenti_ce: "",
    ci: "",
    scadenza_ci: null,
    psog: "",
    scadenza_psog: null,
    cf: "",
    scadenza_cf: null,
    ts: "",
    scadenza_ts: null,
    patente: "",
    scadenza_patente: null,
    unilav: "",
    scadenza_unilav: null,
    idoneita: "",
    scadenza_idoneita: null,
    formazione: "",
    scadenza_formazione: null,
    preposto: "",
    scadenza_preposto: null,
    dirigente: "",
    scadenza_dirigente: null,
    rspp: "",
    scadenza_rspp: null,
    rls: "",
    scadenza_rls: null,
    diisocianati: "",
    scadenza_diisocianati: null,
    ps: "",
    scadenza_ps: null,
    antincendio: "",
    scadenza_antincendio: null,
    dpi3cat: "",
    scadenza_dpi3cat: null,
    saldatore: "",
    scadenza_saldatore: null,
    ponteggi: "",
    scadenza_ponteggi: null,
    quota: "",
    scadenza_quota: null,
    ambconf: "",
    scadenza_ambconf: null,
    dumper: "",
    scadenza_dumper: null,
    pfr_pd: "",
    scadenza_pfr_pd: null,
    pfr_gd: "",
    scadenza_pfr_gd: null,
    ple: "",
    scadenza_ple: null,
    carroponte: "",
    scadenza_carroponte: null,
    pompecls: "",
    scadenza_pompecls: null,
    grutorre: "",
    scadenza_grutorre: null,
    elev: "",
    scadenza_elev: null,
    autogru: "",
    scadenza_autogru: null,
    mmt: "",
    scadenza_mmt: null,
    altro1: "",
    scadenza_altro1: null,
    altro2: "",
    scadenza_altro2: null,
  });
  const [mansioni, setMansioni] = useState([]);
  const [mansioniSel, setMansioniSel] = useState([]);

  const [valueTop, setValueTop] = useState(1);
  const [searchReady, setSearchReady] = useState(false);
  const [visibile, setVisibile] = useState({
    ci: false,
    psog: false,
    cf: false,
    ts: false,
    patente: false,
    unilav: false,
    idoneita: false,
    formazione: false,
    preposto: false,
    dirigente: false,
    rspp: false,
    rls: false,
    diisocianati: false,
    ps: false,
    antincendio: false,
    dpi3cat: false,
    saldatore: false,
    ponteggi: false,
    quota: false,
    ambconf: false,
    dumper: false,
    pfr_pd: false,
    pfr_gd: false,
    ple: false,
    carroponte: false,
    pompecls: false,
    grutorre: false,
    elev: false,
    autogru: false,
    mmt: false,
    altro1: false,
    altro2: false,
    limitazione: false,
    prescrizione: false,
  });
  const [scadenza, setScadenza] = useState({
    ci: false,
    psog: false,
    cf: false,
    ts: false,
    patente: false,
    unilav: false,
    idoneita: false,
    formazione: false,
    preposto: false,
    dirigente: false,
    rspp: false,
    rls: false,
    diisocianati: false,
    ps: false,
    antincendio: false,
    dpi3cat: false,
    saldatore: false,
    ponteggi: false,
    quota: false,
    ambconf: false,
    dumper: false,
    pfr_pd: false,
    pfr_gd: false,
    ple: false,
    carroponte: false,
    pompecls: false,
    grutorre: false,
    elev: false,
    autogru: false,
    mmt: false,
    altro1: false,
    altro2: false,
  });

  const [valueUpload, setValueUpload] = useState("");
  const [colonnaDb, setColonnaDb] = useState("");

  const [nomiDocumenti, setNomiDocumenti] = useState({ from: "", to: "" });
  const [pathDocumenti, setPathDocumenti] = useState([]);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const permission = JSON.parse(localStorage.getItem("permission"));
  const [openSnack, setOpenSnack] = useState(false);
  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };
  const [snack, setSnack] = useState({
    severity: "success",
    messaggio: "Salvataggio Effettuato correttamente",
    open: false,
  });

  const columnsDoc = [
    {
      field: "descrizione",
      headerName: "Descrizione",
      width: 200,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "import",
      headerName: "Importa",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <CButton
          className="btn btn-secondary rounded"
          onClick={(e) => {
            setValueUpload(params.row.id);
            setColonnaDb(params.row.id);
            document.getElementById("formFile").click();
          }}
          style={{
            display:
              location.state.operazione === "Nuovo"
                ? "none"
                : JSON.parse(localStorage.getItem("permission")).report
                    .allowEdit === 0
                ? "none"
                : params.row.file === "" ||
                  params.row.file === null ||
                  params.row.file === undefined
                ? "block"
                : "none",
          }}
        >
          <i className="bi bi-upload"></i>
        </CButton>
      ),
    },
    {
      field: "*",
      headerName: "Download",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <CButton
          className="btn btn-secondary rounded"
          onClick={(e) => manageFiles(params.row.file)}
          style={{
            display: params.row.file ? "block" : "none",
          }}
        >
          <i className="bi bi-download"></i>
        </CButton>
      ),
    },
    {
      field: "**",
      headerName: "Visualizzazione",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <CButton
            className="btn btn-secondary rounded"
            onClick={(e) => viewFiles(params.row.file)}
            style={{
              display: params.row.file ? "block" : "none",
            }}
          >
            <i className="bi bi-eye"></i>
          </CButton>
        </>
      ),
    },
    {
      field: "***",
      headerName: "Eliminazione",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          {JSON.parse(localStorage.getItem("permission")).report.allowDelete !==
          0 ? (
            <CButton
              className="btn btn-secondary rounded"
              onClick={(e) => deleteFiles(params.row.file, params.row.id)}
              style={{
                display: params.row.file ? "block" : "none",
              }}
            >
              <i className="bi bi-trash"></i>
            </CButton>
          ) : (
            <></>
          )}
        </>
      ),
    },
    {
      field: "scadenza",
      headerName: "Scadenza",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => {
        if (params.row.id === "prescrizione") {
          <></>;
        } else if (params.row.id === "limitazione") {
          <></>;
        } else {
          return (
            <>
              <CFormFloating className="">
                <CFormSwitch
                  checked={scadenza[params.row.id]}
                  disabled={
                    JSON.parse(localStorage.getItem("permission")).report
                      .allowEdit === 0
                  }
                  id={params.row.id}
                  style={{
                    backgroundColor:
                      moment(new Date()).local().format("YYYY-MM-DD") >=
                      moment(params.row.scadenza).local().format("YYYY-MM-DD")
                        ? "red"
                        : "",
                    borderColor: "grey",
                  }}
                  onChange={(e) => {
                    if (e.target.checked) {
                      let scad = { ...scadenza };
                      scad[params.row.id] = true;
                      setScadenza(scad);
                    } else {
                      let pers = { ...personale };
                      pers["scadenza_" + params.row.id] = null;
                      setPersonale(pers);
                      let scad = { ...scadenza };
                      scad[params.row.id] = false;
                      setScadenza(scad);
                    }
                  }}
                />
              </CFormFloating>
              <CCollapse className="" visible={scadenza[params.row.id]}>
                <CCol>
                  <CFormFloating>
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      locale='it'
                    >
                      <DatePicker
                        label="Scadenza"
                        value={moment(params.row.scadenza)}
                        renderInput={(params) => (
                          <TextField {...params} className="p-0" />
                        )}
                        onChange={(newValue) => {
                          let pers = { ...personale };
                          pers["scadenza_" + params.row.id] =
                            moment(newValue).format("YYYY-MM-DD");
                          setPersonale(pers);
                          let scad = { ...scadenza };
                          scad[params.row.id] = true;
                          setScadenza(scad);
                        }}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).report
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                    </LocalizationProvider>
                  </CFormFloating>
                </CCol>
              </CCollapse>
            </>
          );
        }
      },
    },
    {
      field: "****",
      headerName: "Modifica",
      width: 350,
      headerAlign: "center",
      align: "center",
      renderCell: (
        params //mi aiuta a mettere il valore
      ) => (
        <>
          <CButton
            className={`btn btn-secondary rounded ${
              params.row.file ? "d-block" : "d-none"
            }`}
            style={{ display: displayNone() }}
            onClick={() => {
              let vis = { ...visibile };
              vis[params.row.id] = !visibile[params.row.id];
              setVisibile(vis);
            }}
          >
            <i className="bi bi-pen"></i>
          </CButton>
          <CCollapse
            visible={visibile[params.row.id]}
            className="col-md col-12"
          >
            <CCol>
              <CFormInput
                type="text"
                id={params.row.id}
                className="col"
                name={params.row.id}
                defaultValue={personale[params.row.id]}
                onChange={handleChange}
                disabled={
                  JSON.parse(localStorage.getItem("permission")).report
                    .allowEdit === 0
                }
              />
            </CCol>
          </CCollapse>
        </>
      ),
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer className="d-flex flex-wrap m-3">
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  const displayNone = () => {
    if (
      JSON.parse(localStorage.getItem("permission")).report.allowDelete === 0
    ) {
      return "none";
    } else {
      return "block";
    }
  };
  const changeDocumentPath = (path) => {
    let newPath = { ...path };

    let arrOfPath = [...pathDocumenti];
    if (arrOfPath.length > 0) {
      let elIndex = arrOfPath.findIndex((e) => e.from === newPath.from);

      if (elIndex !== -1) {
        arrOfPath[elIndex].to = newPath.to;
      } else {
        arrOfPath.push(newPath);
      }
    } else {
      arrOfPath.push(newPath);
    }
    setPathDocumenti(arrOfPath);
  };
  const handleChange = (e) => {
    if (
      e.target.name === "ci" ||
      e.target.name === "psog" ||
      e.target.name === "cf" ||
      e.target.name === "ts" ||
      e.target.name === "patente" ||
      e.target.name === "unilav" ||
      e.target.name === "idoneita" ||
      e.target.name === "formazione" ||
      e.target.name === "preposto" ||
      e.target.name === "dirigente" ||
      e.target.name === "rspp" ||
      e.target.name === "rls" ||
      e.target.name === "diisocianati" ||
      e.target.name === "ps" ||
      e.target.name === "antincendio" ||
      e.target.name === "dpi3cat" ||
      e.target.name === "saldatore" ||
      e.target.name === "ponteggi" ||
      e.target.name === "quota" ||
      e.target.name === "ambconf" ||
      e.target.name === "dumper" ||
      e.target.name === "pfr_pd" ||
      e.target.name === "pfr_gd" ||
      e.target.name === "ple" ||
      e.target.name === "carroponte" ||
      e.target.name === "pompecls" ||
      e.target.name === "grutorre" ||
      e.target.name === "elev" ||
      e.target.name === "autogru" ||
      e.target.name === "mmt" ||
      e.target.name === "altro1" ||
      e.target.name === "altro2"
    ) {
      let valDocumenti = { ...nomiDocumenti };
      valDocumenti.from = "/" + personaleBackup[e.target.name];
      valDocumenti.to = "/" + e.target.value;
      changeDocumentPath(valDocumenti);
    }
    let copiaPersonale = { ...personale };
    copiaPersonale[e.target.name] = e.target.value;
    copiaPersonale.data_inizio = copiaPersonale.data_inizio
      ? moment(copiaPersonale.data_inizio).format("YYYY-MM-DD")
      : null;
    copiaPersonale.data_fine = copiaPersonale.data_fine
      ? moment(copiaPersonale.data_fine).format("YYYY-MM-DD")
      : null;
    copiaPersonale.data_nascita = copiaPersonale.data_nascita
      ? moment(copiaPersonale.data_nascita).format("YYYY-MM-DD")
      : null;
    setPersonale(copiaPersonale);
  };
  const handleDelete = async () => {
    let confirm = window.confirm(
      "Sei sicuro di voler cancellare il personale?"
    );
    if (confirm) {
      await api.delete("/api/personale", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
        data: {
          matricola: personale.matricola,
        },
      });
      alert("Manutenzione eliminata con successo");
    }
  };
  const manageFiles = async (cFile) => {
    let f = cFile.split("/");

    let file = f[2];

    await api
      .post(
        "api/ftp",
        { file: file, percorso: cFile, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        //window.open("https://portale.fondamentasrl.it/file?file=" + file);

        var oReq = new XMLHttpRequest();
        // The Endpoint of your server
        var URLToPDF = "https://portale.fondamentasrl.it/file?file=" + file;
        // Configure XMLHttpRequest
        oReq.open("GET", URLToPDF, true);

        // Important to use the blob response type
        oReq.responseType = "blob";

        // When the file request finishes
        // Is up to you, the configuration for error events etc.
        oReq.onload = function () {
          // Once the file is downloaded, open a new window with the PDF
          // Remember to allow the POP-UPS in your browser
          var filee = new Blob([oReq.response], {
            type: "application/pdf",
          });

          // Generate file download directly in the browser !
          saveAs(filee, file);
        };

        oReq.send();
      });
  };
  const viewFiles = async (cFile) => {
    let f = cFile.split("/");

    let file = f[2];

    await api
      .post(
        "api/ftp",
        { file: file, percorso: cFile, operazione: "prendi" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        window.open("https://portale.fondamentasrl.it/file?file=" + file);
      });
  };
  const deleteFiles = async (name, colonnaDb) => {
    let perc = name;
    let isExecuted = window.confirm(
      "Sei sicuro di voler cancellare il documento " + colonnaDb + "?"
    );
    if (isExecuted) {
      try {
        await api.post(
          "api/ftp",
          { percorso: perc, operazione: "elimina" },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        //#region
        let copiaPersonale = { ...personale };
        copiaPersonale.data_inizio = copiaPersonale.data_inizio
          ? moment(copiaPersonale.data_inizio).format("YYYY-MM-DD")
          : null;
        copiaPersonale.data_fine = copiaPersonale.data_fine
          ? moment(copiaPersonale.data_fine).format("YYYY-MM-DD")
          : null;
        copiaPersonale.data_nascita = copiaPersonale.data_nascita
          ? moment(copiaPersonale.data_nascita).format("YYYY-MM-DD")
          : null;
        if (
          copiaPersonale.scadenza_ci !== "" &&
          copiaPersonale.scadenza_ci !== null
        ) {
          copiaPersonale.scadenza_ci = moment(copiaPersonale.scadenza_ci)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_psog !== "" &&
          copiaPersonale.scadenza_psog !== null
        ) {
          copiaPersonale.scadenza_psog = moment(copiaPersonale.scadenza_psog)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_cf !== "" &&
          copiaPersonale.scadenza_cf !== null
        ) {
          copiaPersonale.scadenza_cf = moment(copiaPersonale.scadenza_cf)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ts !== "" &&
          copiaPersonale.scadenza_ts !== null
        ) {
          copiaPersonale.scadenza_ts = moment(copiaPersonale.scadenza_ts)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_patente !== "" &&
          copiaPersonale.scadenza_patente !== null
        ) {
          copiaPersonale.scadenza_patente = moment(
            copiaPersonale.scadenza_patente
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_unilav !== "" &&
          copiaPersonale.scadenza_unilav !== null
        ) {
          copiaPersonale.scadenza_unilav = moment(
            copiaPersonale.scadenza_unilav
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_idoneita !== "" &&
          copiaPersonale.scadenza_idoneita !== null
        ) {
          copiaPersonale.scadenza_idoneita = moment(
            copiaPersonale.scadenza_idoneita
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_formazione !== "" &&
          copiaPersonale.scadenza_formazione !== null
        ) {
          copiaPersonale.scadenza_formazione = moment(
            copiaPersonale.scadenza_formazione
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_preposto !== "" &&
          copiaPersonale.scadenza_preposto !== null
        ) {
          copiaPersonale.scadenza_preposto = moment(
            copiaPersonale.scadenza_preposto
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_dirigente !== "" &&
          copiaPersonale.scadenza_dirigente !== null
        ) {
          copiaPersonale.scadenza_dirigente = moment(
            copiaPersonale.scadenza_dirigente
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_rspp !== "" &&
          copiaPersonale.scadenza_rspp !== null
        ) {
          copiaPersonale.scadenza_rspp = moment(copiaPersonale.scadenza_rspp)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_rls !== "" &&
          copiaPersonale.scadenza_rls !== null
        ) {
          copiaPersonale.scadenza_rls = moment(copiaPersonale.scadenza_rls)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_diisocianati !== "" &&
          copiaPersonale.scadenza_diisocianati !== null
        ) {
          copiaPersonale.scadenza_diisocianati = moment(
            copiaPersonale.scadenza_diisocianati
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ps !== "" &&
          copiaPersonale.scadenza_ps !== null
        ) {
          copiaPersonale.scadenza_ps = moment(copiaPersonale.scadenza_ps)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_antincendio !== "" &&
          copiaPersonale.scadenza_antincendio !== null
        ) {
          copiaPersonale.scadenza_antincendio = moment(
            copiaPersonale.scadenza_antincendio
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_dpi3cat !== "" &&
          copiaPersonale.scadenza_dpi3cat !== null
        ) {
          copiaPersonale.scadenza_dpi3cat = moment(
            copiaPersonale.scadenza_dpi3cat
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_saldatore !== "" &&
          copiaPersonale.scadenza_saldatore !== null
        ) {
          copiaPersonale.scadenza_saldatore = moment(
            copiaPersonale.scadenza_saldatore
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ponteggi !== "" &&
          copiaPersonale.scadenza_ponteggi !== null
        ) {
          copiaPersonale.scadenza_ponteggi = moment(
            copiaPersonale.scadenza_ponteggi
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_quota !== "" &&
          copiaPersonale.scadenza_quota !== null
        ) {
          copiaPersonale.scadenza_quota = moment(copiaPersonale.scadenza_quota)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ambconf !== "" &&
          copiaPersonale.scadenza_ambconf !== null
        ) {
          copiaPersonale.scadenza_ambconf = moment(
            copiaPersonale.scadenza_ambconf
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_dumper !== "" &&
          copiaPersonale.scadenza_dumper !== null
        ) {
          copiaPersonale.scadenza_dumper = moment(
            copiaPersonale.scadenza_dumper
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_pfr_pd !== "" &&
          copiaPersonale.scadenza_pfr_pd !== null
        ) {
          copiaPersonale.scadenza_pfr_pd = moment(
            copiaPersonale.scadenza_pfr_pd
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_pfr_gd !== "" &&
          copiaPersonale.scadenza_pfr_gd !== null
        ) {
          copiaPersonale.scadenza_pfr_gd = moment(
            copiaPersonale.scadenza_pfr_gd
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ple !== "" &&
          copiaPersonale.scadenza_ple !== null
        ) {
          copiaPersonale.scadenza_ple = moment(copiaPersonale.scadenza_ple)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_carroponte !== "" &&
          copiaPersonale.scadenza_carroponte !== null
        ) {
          copiaPersonale.scadenza_carroponte = moment(
            copiaPersonale.scadenza_carroponte
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_pompecls !== "" &&
          copiaPersonale.scadenza_pompecls !== null
        ) {
          copiaPersonale.scadenza_pompecls = moment(
            copiaPersonale.scadenza_pompecls
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_grutorre !== "" &&
          copiaPersonale.scadenza_grutorre !== null
        ) {
          copiaPersonale.scadenza_grutorre = moment(
            copiaPersonale.scadenza_grutorre
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_elev !== "" &&
          copiaPersonale.scadenza_elev !== null
        ) {
          copiaPersonale.scadenza_elev = moment(copiaPersonale.scadenza_elev)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_autogru !== "" &&
          copiaPersonale.scadenza_autogru !== null
        ) {
          copiaPersonale.scadenza_autogru = moment(
            copiaPersonale.scadenza_autogru
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_mmt !== "" &&
          copiaPersonale.scadenza_mmt !== null
        ) {
          copiaPersonale.scadenza_mmt = moment(
            copiaPersonale.scadenza_mmt
          ).format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_altro1 !== "" &&
          copiaPersonale.scadenza_altro1 !== null
        ) {
          copiaPersonale.scadenza_altro1 = moment(
            copiaPersonale.scadenza_altro1
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_altro2 !== "" &&
          copiaPersonale.scadenza_altro2 !== null
        ) {
          copiaPersonale.scadenza_altro2 = moment(
            copiaPersonale.scadenza_altro2
          )
            .local()
            .format("YYYY-MM-DD");
        }
        //#endregion
        copiaPersonale[colonnaDb] = "";
        copiaPersonale["scadenza_" + colonnaDb] = null;
        let scad = { ...scadenza };
        scad[colonnaDb] = false;
        setScadenza(scad);
        try {
          await api.put(
            "api/personale",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              personale: copiaPersonale,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          setSnack({
            severity: "success",
            messaggio: "File Eliminato con successo",
          });
          setOpenSnack(true);
          reloadData();
        } catch (error) {
          setSnack({
            severity: "error",
            messaggio: "Eliminazione persorso file fallito",
          });
          setOpenSnack(true);
        }
      } catch (error) {
        setSnack({
          severity: "error",
          messaggio:
            "Eliminazione fallita, controllare presenza del file sul NAS",
        });
        setOpenSnack(true);
      }
    }
  };
  const modifyPersonale = async () => {
    if (personale.matricola === "" || personale.matricola === undefined) {
      setSnack({
        severity: "error",
        messaggio: "Attenzione la matricola non può essere vuota",
      });
      setOpenSnack(true);
    } else {
      try {
        let copiaPersonale = { ...personale };
        copiaPersonale.data_inizio = copiaPersonale.data_inizio
          ? moment(copiaPersonale.data_inizio).format("YYYY-MM-DD")
          : null;
        copiaPersonale.data_fine = copiaPersonale.data_fine
          ? moment(copiaPersonale.data_fine).format("YYYY-MM-DD")
          : null;
        copiaPersonale.data_nascita = copiaPersonale.data_nascita
          ? moment(copiaPersonale.data_nascita).format("YYYY-MM-DD")
          : null;
        if (
          copiaPersonale.scadenza_ci !== "" &&
          copiaPersonale.scadenza_ci !== null
        ) {
          copiaPersonale.scadenza_ci = moment(copiaPersonale.scadenza_ci)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_psog !== "" &&
          copiaPersonale.scadenza_psog !== null
        ) {
          copiaPersonale.scadenza_psog = moment(copiaPersonale.scadenza_psog)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_cf !== "" &&
          copiaPersonale.scadenza_cf !== null
        ) {
          copiaPersonale.scadenza_cf = moment(copiaPersonale.scadenza_cf)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ts !== "" &&
          copiaPersonale.scadenza_ts !== null
        ) {
          copiaPersonale.scadenza_ts = moment(copiaPersonale.scadenza_ts)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_patente !== "" &&
          copiaPersonale.scadenza_patente !== null
        ) {
          copiaPersonale.scadenza_patente = moment(
            copiaPersonale.scadenza_patente
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_unilav !== "" &&
          copiaPersonale.scadenza_unilav !== null
        ) {
          copiaPersonale.scadenza_unilav = moment(
            copiaPersonale.scadenza_unilav
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_idoneita !== "" &&
          copiaPersonale.scadenza_idoneita !== null
        ) {
          copiaPersonale.scadenza_idoneita = moment(
            copiaPersonale.scadenza_idoneita
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_formazione !== "" &&
          copiaPersonale.scadenza_formazione !== null
        ) {
          copiaPersonale.scadenza_formazione = moment(
            copiaPersonale.scadenza_formazione
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_preposto !== "" &&
          copiaPersonale.scadenza_preposto !== null
        ) {
          copiaPersonale.scadenza_preposto = moment(
            copiaPersonale.scadenza_preposto
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_dirigente !== "" &&
          copiaPersonale.scadenza_dirigente !== null
        ) {
          copiaPersonale.scadenza_dirigente = moment(
            copiaPersonale.scadenza_dirigente
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_rspp !== "" &&
          copiaPersonale.scadenza_rspp !== null
        ) {
          copiaPersonale.scadenza_rspp = moment(copiaPersonale.scadenza_rspp)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_rls !== "" &&
          copiaPersonale.scadenza_rls !== null
        ) {
          copiaPersonale.scadenza_rls = moment(copiaPersonale.scadenza_rls)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_diisocianati !== "" &&
          copiaPersonale.scadenza_diisocianati !== null
        ) {
          copiaPersonale.scadenza_diisocianati = moment(
            copiaPersonale.scadenza_diisocianati
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ps !== "" &&
          copiaPersonale.scadenza_ps !== null
        ) {
          copiaPersonale.scadenza_ps = moment(copiaPersonale.scadenza_ps)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_antincendio !== "" &&
          copiaPersonale.scadenza_antincendio !== null
        ) {
          copiaPersonale.scadenza_antincendio = moment(
            copiaPersonale.scadenza_antincendio
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_dpi3cat !== "" &&
          copiaPersonale.scadenza_dpi3cat !== null
        ) {
          copiaPersonale.scadenza_dpi3cat = moment(
            copiaPersonale.scadenza_dpi3cat
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_saldatore !== "" &&
          copiaPersonale.scadenza_saldatore !== null
        ) {
          copiaPersonale.scadenza_saldatore = moment(
            copiaPersonale.scadenza_saldatore
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ponteggi !== "" &&
          copiaPersonale.scadenza_ponteggi !== null
        ) {
          copiaPersonale.scadenza_ponteggi = moment(
            copiaPersonale.scadenza_ponteggi
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_quota !== "" &&
          copiaPersonale.scadenza_quota !== null
        ) {
          copiaPersonale.scadenza_quota = moment(copiaPersonale.scadenza_quota)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ambconf !== "" &&
          copiaPersonale.scadenza_ambconf !== null
        ) {
          copiaPersonale.scadenza_ambconf = moment(
            copiaPersonale.scadenza_ambconf
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_dumper !== "" &&
          copiaPersonale.scadenza_dumper !== null
        ) {
          copiaPersonale.scadenza_dumper = moment(
            copiaPersonale.scadenza_dumper
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_pfr_pd !== "" &&
          copiaPersonale.scadenza_pfr_pd !== null
        ) {
          copiaPersonale.scadenza_pfr_pd = moment(
            copiaPersonale.scadenza_pfr_pd
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_pfr_gd !== "" &&
          copiaPersonale.scadenza_pfr_gd !== null
        ) {
          copiaPersonale.scadenza_pfr_gd = moment(
            copiaPersonale.scadenza_pfr_gd
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_ple !== "" &&
          copiaPersonale.scadenza_ple !== null
        ) {
          copiaPersonale.scadenza_ple = moment(copiaPersonale.scadenza_ple)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_carroponte !== "" &&
          copiaPersonale.scadenza_carroponte !== null
        ) {
          copiaPersonale.scadenza_carroponte = moment(
            copiaPersonale.scadenza_carroponte
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_pompecls !== "" &&
          copiaPersonale.scadenza_pompecls !== null
        ) {
          copiaPersonale.scadenza_pompecls = moment(
            copiaPersonale.scadenza_pompecls
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_grutorre !== "" &&
          copiaPersonale.scadenza_grutorre !== null
        ) {
          copiaPersonale.scadenza_grutorre = moment(
            copiaPersonale.scadenza_grutorre
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_elev !== "" &&
          copiaPersonale.scadenza_elev !== null
        ) {
          copiaPersonale.scadenza_elev = moment(copiaPersonale.scadenza_elev)
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_autogru !== "" &&
          copiaPersonale.scadenza_autogru !== null
        ) {
          copiaPersonale.scadenza_autogru = moment(
            copiaPersonale.scadenza_autogru
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_mmt !== "" &&
          copiaPersonale.scadenza_mmt !== null
        ) {
          copiaPersonale.scadenza_mmt = moment(
            copiaPersonale.scadenza_mmt
          ).format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_altro1 !== "" &&
          copiaPersonale.scadenza_altro1 !== null
        ) {
          copiaPersonale.scadenza_altro1 = moment(
            copiaPersonale.scadenza_altro1
          )
            .local()
            .format("YYYY-MM-DD");
        }
        if (
          copiaPersonale.scadenza_altro2 !== "" &&
          copiaPersonale.scadenza_altro2 !== null
        ) {
          copiaPersonale.scadenza_altro2 = moment(
            copiaPersonale.scadenza_altro2
          )
            .local()
            .format("YYYY-MM-DD");
        }

        if (location.state.operazione === "Nuovo") {
          const response = await api.post(
            "api/personale",
            {
              auth: "a5d42c3ed7977afa2d8e922569e7940b",
              personale: copiaPersonale,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          );
          location.state.operazione = "Modifica";
          setSnack({
            severity: "success",
            messaggio: "Inserimento effettuato",
          });
          setOpenSnack(true);
          setTimeout(() => {
            navigate("/home/personale", {
              state: {
                search: "",
              },
            });
          }, 500);
        } else {
          if (pathDocumenti.length > 0) {
            try {
              await api.post(
                "api/allegati",
                { rename: true, path: pathDocumenti },
                {
                  responseType: "blob",
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                }
              );

              await api.put(
                "api/personale",
                {
                  auth: "a5d42c3ed7977afa2d8e922569e7940b",
                  personale: copiaPersonale,
                },
                {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                  },
                }
              );

              setSnack({
                severity: "success",
                messaggio: "Modifica effettuata",
              });
              setOpenSnack(true);

              setTimeout(() => {
                navigate("/home/personale", {
                  state: {
                    search: "",
                  },
                });
              }, 500);
            } catch (error) {
              setSnack({
                severity: "error",
                messaggio: "Errore durante la modifica",
              });
              setOpenSnack(true);
            }
          } else {
            await api.put(
              "api/personale",
              {
                auth: "a5d42c3ed7977afa2d8e922569e7940b",
                personale: copiaPersonale,
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("jwt")}`,
                },
              }
            );

            setSnack({
              severity: "success",
              messaggio: "Modifica effettuata",
            });
            setOpenSnack(true);
            setTimeout(() => {
              navigate("/home/personale", {
                state: {
                  search: "",
                },
              });
            }, 500);
          }
        }
      } catch {
        setSnack({
          severity: "error",
          messaggio: "Errore nel salvataggio",
        });
      }
    }
  };
  const loadFileProfile = async (filex, nome) => {
    setOpenBackdrop(true);

    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", personale.matricola);
    //formData.append("data", data);
    formData.append(
      "nomeManutenzione",
      "PERSONALE/" +
        personale.matricola +
        "_" +
        personale.cognome.split(" ").join("").toUpperCase().slice(0, 10) +
        "_" +
        personale.nome.split(" ").join("").toUpperCase().slice(0, 10) +
        "/" +
        personale.matricola +
        "_profilo" +
        ".jpg"
    );
    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        setOpenBackdrop(false);
        setSnack({
          severity: "success",
          messaggio: "Documento caricato con successo!",
        });
        setOpenSnack(true);
        let pers = { ...personale };
        pers.foto =
          "PERSONALE/" +
          personale.matricola +
          "_" +
          personale.cognome.split(" ").join("").toUpperCase().slice(0, 10) +
          "_" +
          personale.nome.split(" ").join("").toUpperCase().slice(0, 10) +
          "/" +
          personale.matricola +
          "_profilo" +
          ".jpg";
        pers.data_inizio = pers.data_inizio
          ? moment(pers.data_inizio).format("YYYY-MM-DD")
          : null;
        pers.data_fine = pers.data_fine
          ? moment(pers.data_fine).format("YYYY-MM-DD")
          : null;
        pers.data_nascita = pers.data_nascita
          ? moment(pers.data_nascita).format("YYYY-MM-DD")
          : null;
        api
          .put(
            "api/personale",
            { auth: "a5d42c3ed7977afa2d8e922569e7940b", personale: pers },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("jwt")}`,
              },
            }
          )
          .then((res) => {
            console.log(res);
            //reload();
          })
          .catch((error) => {
            console.log(error);
          });
      });
  };
  const loadFile = async (filex, matricola, nome, nomeColonnaDb) => {
    setOpenBackdrop(true);

    const formData = new FormData();
    //filex.name=matricola+"_"+moment(data).format("YYMMDD")+".pdf";

    formData.append("file", filex);
    formData.append("matricola", matricola);
    //formData.append("data", data);
    formData.append(
      "personale",
      "PERSONALE/" +
        personale.matricola +
        "_" +
        personale.cognome.split(" ").join("").toUpperCase().slice(0, 10) +
        "_" +
        personale.nome.split(" ").join("").toUpperCase().slice(0, 10) +
        "/" +
        personale.matricola +
        "_" +
        nome +
        ".pdf"
    );
    formData.append("docMacch", false);

    await api
      .post("api/ftp/caricaDoc", formData, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then(async () => {
        setOpenBackdrop(false);
        setSnack({
          severity: "success",
          messaggio: "Documento caricato con successo!",
        });
        setOpenSnack(true);
        let pers = { ...personale };
        pers.data_inizio = pers.data_inizio
          ? moment(pers.data_inizio).format("YYYY-MM-DD")
          : null;
        pers.data_fine = pers.data_fine
          ? moment(pers.data_fine).format("YYYY-MM-DD")
          : null;
        pers.data_nascita = pers.data_nascita
          ? moment(pers.data_nascita).format("YYYY-MM-DD")
          : null;

        pers.scadenza_ci
          ? (pers.scadenza_ci = moment(pers.scadenza_ci).format("YYYY-MM-DD"))
          : (pers.scadenza_ci = null);
        pers.scadenza_psog
          ? (pers.scadenza_psog = moment(pers.scadenza_psog).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_psog = null);
        pers.scadenza_cf
          ? (pers.scadenza_cf = moment(pers.scadenza_cf).format("YYYY-MM-DD"))
          : (pers.scadenza_cf = null);
        pers.scadenza_ts
          ? (pers.scadenza_ts = moment(pers.scadenza_ts).format("YYYY-MM-DD"))
          : (pers.scadenza_ts = null);
        pers.scadenza_patente
          ? (pers.scadenza_patente = moment(pers.scadenza_patente).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_patente = null);

        pers.scadenza_unilav
          ? (pers.scadenza_unilav = moment(pers.scadenza_unilav).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_unilav = null);

        pers.scadenza_idoneita
          ? (pers.scadenza_idoneita = moment(pers.scadenza_idoneita).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_idoneita = null);

        pers.scadenza_formazione
          ? (pers.scadenza_formazione = moment(pers.scadenza_formazione).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_formazione = null);

        pers.scadenza_preposto
          ? (pers.scadenza_preposto = moment(pers.scadenza_preposto).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_preposto = null);

        pers.scadenza_dirigente
          ? (pers.scadenza_dirigente = moment(pers.scadenza_dirigente).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_dirigente = null);

        pers.scadenza_rspp
          ? (pers.scadenza_rspp = moment(pers.scadenza_rspp).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_rspp = null);

        pers.scadenza_rls
          ? (pers.scadenza_rls = moment(pers.scadenza_rls).format("YYYY-MM-DD"))
          : (pers.scadenza_rls = null);

        pers.scadenza_diisocianati
          ? (pers.scadenza_diisocianati = moment(
              pers.scadenza_diisocianati
            ).format("YYYY-MM-DD"))
          : (pers.scadenza_diisocianati = null);

        pers.scadenza_ps
          ? (pers.scadenza_ps = moment(pers.scadenza_ps).format("YYYY-MM-DD"))
          : (pers.scadenza_ps = null);

        pers.scadenza_antincendio
          ? (pers.scadenza_antincendio = moment(
              pers.scadenza_antincendio
            ).format("YYYY-MM-DD"))
          : (pers.scadenza_antincendio = null);

        pers.scadenza_dpi3cat
          ? (pers.scadenza_dpi3cat = moment(pers.scadenza_dpi3cat).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_dpi3cat = null);

        pers.scadenza_saldatore
          ? (pers.scadenza_saldatore = moment(pers.scadenza_saldatore).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_ponteggi = null);
        pers.scadenza_ponteggi
          ? (pers.scadenza_ponteggi = moment(pers.scadenza_ponteggi).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_quota = null);
        pers.scadenza_quota
          ? (pers.scadenza_quota = moment(pers.scadenza_quota).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_ambconf = null);
        pers.scadenza_ambconf
          ? (pers.scadenza_ambconf = moment(pers.scadenza_ambconf).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_dumper = null);
        pers.scadenza_dumper
          ? (pers.scadenza_dumper = moment(pers.scadenza_dumper).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_pfr_pd = null);
        pers.scadenza_pfr_pd
          ? (pers.scadenza_pfr_pd = moment(pers.scadenza_pfr_pd).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_pfr_gd = null);
        pers.scadenza_pfr_gd
          ? (pers.scadenza_pfr_gd = moment(pers.scadenza_pfr_gd).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_ple = null);
        pers.scadenza_ple
          ? (pers.scadenza_ple = moment(pers.scadenza_ple).format("YYYY-MM-DD"))
          : (pers.scadenza_carroponte = null);
        pers.scadenza_carroponte
          ? (pers.scadenza_carroponte = moment(pers.scadenza_carroponte).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_pompecls = null);
        pers.scadenza_pompecls
          ? (pers.scadenza_pompecls = moment(pers.scadenza_pompecls).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_grutorre = null);
        pers.scadenza_grutorre
          ? (pers.scadenza_grutorre = moment(pers.scadenza_grutorre).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_elev = null);
        pers.scadenza_elev
          ? (pers.scadenza_elev = moment(pers.scadenza_elev).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_autogru = null);
        pers.scadenza_autogru
          ? (pers.scadenza_autogru = moment(pers.scadenza_autogru).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_mmt = null);
        pers.scadenza_mmt
          ? (pers.scadenza_mmt = moment(pers.scadenza_mmt).format("YYYY-MM-DD"))
          : (pers.scadenza_altro1 = null);
        pers.scadenza_altro1
          ? (pers.scadenza_altro1 = moment(pers.scadenza_altro1).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_altro2 = null);
        pers.scadenza_altro2
          ? (pers.scadenza_altro2 = moment(pers.scadenza_altro2).format(
              "YYYY-MM-DD"
            ))
          : (pers.scadenza_altro2 = null);

        pers[`${nomeColonnaDb}`] =
          "PERSONALE/" +
          personale.matricola +
          "_" +
          personale.cognome.split(" ").join("").toUpperCase().slice(0, 10) +
          "_" +
          personale.nome.split(" ").join("").toUpperCase().slice(0, 10) +
          "/" +
          matricola +
          "_" +
          nome +
          ".pdf";
        await api.put(
          "api/personale",
          {
            personale: pers,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwt")}`,
            },
          }
        );
        await reloadData();
      });
  };
  const handleChangeValueTop = (event, newValue) => {
    setValueTop(newValue);
  };
  const reloadData = async () => {
    const res = await api.get("api/personale", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwt")}`,
      },
    });
    const personaleFiltered = res.data.data.filter(
      (el) => el.matricola === personale.matricola
    );
    setPersonaleBackup(personaleFiltered[0]);
    setPersonale(personaleFiltered[0]);
  };
  const zipAllFiles = async () => {
    api
      .post(
        "api/allegati",
        {
          personale:
            personale.matricola +
            "_" +
            personale.cognome.split(" ").join("").toUpperCase().slice(0, 10) +
            "_" +
            personale.nome.split(" ").join("").toUpperCase().slice(0, 10),
        },
        {
          responseType: "blob",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(
          new Blob([res.data], { type: "application/zip" })
        ); //prendo il file, lo rendo blob lo aggancio a un link e scarico

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", personale.matricola + ".zip");
        document.body.appendChild(link);
        link.click();
      });
  };
  useEffect(() => {
    // è un loop qui carico i dati
    const fetchData = async () => {
      api
        .get("api/mansioni", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          const tipologia = res.data.data;
          setMansioni(tipologia);
          setMansioniSel(
            location.state.operazione === "Nuovo"
              ? null
              : tipologia?.filter(
                  (el) => el.id === location.state.personale?.mansione
                )[0]
          );
          setSearchReady(true);
        });

      if (location.state.operazione === "Nuovo") {
        const pers = { ...personale };
        setPersonaleBackup(pers);
        setPersonale(pers);
      } else {
        const personale = location.state.personale;
        setPersonaleBackup(personale);
        setPersonale(personale);

        let url = `https://portale.fondamentasrl.it/home/personale/qrcode/${location.state.personale.matricola}`;
        //QRCode.toDataURL(url).then((data) => setQrcode(data));

        let scad = { ...scadenza };
        location.state.personale.scadenza_ci !== null
          ? (scad.ci = true)
          : (scad.ci = false);
        location.state.personale.scadenza_psog !== null
          ? (scad.psog = true)
          : (scad.psog = false);
        location.state.personale.scadenza_cf !== null
          ? (scad.cf = true)
          : (scad.cf = false);
        location.state.personale.scadenza_ts !== null
          ? (scad.ts = true)
          : (scad.ts = false);
        location.state.personale.scadenza_patente !== null
          ? (scad.patente = true)
          : (scad.patente = false);
        location.state.personale.scadenza_unilav !== null
          ? (scad.unilav = true)
          : (scad.unilav = false);
        location.state.personale.scadenza_idoneita !== null
          ? (scad.idoneita = true)
          : (scad.idoneita = false);
        location.state.personale.scadenza_formazione !== null
          ? (scad.formazione = true)
          : (scad.formazione = false);
        location.state.personale.scadenza_preposto !== null
          ? (scad.preposto = true)
          : (scad.preposto = false);
        location.state.personale.scadenza_dirigente !== null
          ? (scad.dirigente = true)
          : (scad.dirigente = false);
        location.state.personale.scadenza_rspp !== null
          ? (scad.rspp = true)
          : (scad.rspp = false);
        location.state.personale.scadenza_rls !== null
          ? (scad.rls = true)
          : (scad.rls = false);
        location.state.personale.scadenza_diisocianati !== null
          ? (scad.diisocianati = true)
          : (scad.diisocianati = false);
        location.state.personale.scadenza_ps !== null
          ? (scad.ps = true)
          : (scad.ps = false);
        location.state.personale.scadenza_antincendio !== null
          ? (scad.antincendio = true)
          : (scad.antincendio = false);
        location.state.personale.scadenza_dpi3cat !== null
          ? (scad.dpi3cat = true)
          : (scad.dpi3cat = false);
        location.state.personale.scadenza_saldatore !== null
          ? (scad.saldatore = true)
          : (scad.saldatore = false);
        location.state.personale.scadenza_ponteggi !== null
          ? (scad.ponteggi = true)
          : (scad.ponteggi = false);
        location.state.personale.scadenza_quota !== null
          ? (scad.quota = true)
          : (scad.quota = false);
        location.state.personale.scadenza_ambconf !== null
          ? (scad.ambconf = true)
          : (scad.ambconf = false);
        location.state.personale.scadenza_dumper !== null
          ? (scad.dumper = true)
          : (scad.dumper = false);
        location.state.personale.scadenza_pfr_pd !== null
          ? (scad.pfr_pd = true)
          : (scad.pfr_pd = false);
        location.state.personale.scadenza_pfr_gd !== null
          ? (scad.pfr_gd = true)
          : (scad.pfr_gd = false);
        location.state.personale.scadenza_ple !== null
          ? (scad.ple = true)
          : (scad.ple = false);
        location.state.personale.scadenza_carroponte !== null
          ? (scad.carroponte = true)
          : (scad.carroponte = false);
        location.state.personale.scadenza_pompecls !== null
          ? (scad.pompecls = true)
          : (scad.pompecls = false);
        location.state.personale.scadenza_grutorre !== null
          ? (scad.grutorre = true)
          : (scad.grutorre = false);
        location.state.personale.scadenza_elev !== null
          ? (scad.elev = true)
          : (scad.elev = false);
        location.state.personale.scadenza_autogru !== null
          ? (scad.autogru = true)
          : (scad.autogru = false);
        location.state.personale.scadenza_mmt !== null
          ? (scad.mmt = true)
          : (scad.mmt = false);
        location.state.personale.scadenza_altro1 !== null
          ? (scad.altro1 = true)
          : (scad.altro1 = false);
        location.state.personale.scadenza_altro2 !== null
          ? (scad.altro2 = true)
          : (scad.altro2 = false);

        setScadenza(scad);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="mt-5 pt-5 p-md-5 p-0">
      <GlobalSnackbar
        severity={snack.severity}
        messaggio={snack.messaggio}
        openSnack={openSnack}
        handleCloseSnack={handleCloseSnack}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="card text-center  border-classic mx-4 mx-md-0">
        <div className="card-header position-relative">
          <span className="fs-4 col">Dettaglio Personale</span>
          <span
            className="position-absolute top-0 start-0 translate-middle badge rounded-pill bg-blue border border-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              if (location.state.search !== undefined) {
                navigate("/home/personale", {
                  state: {
                    search: location.state.search,
                  },
                });
              } else {
                navigate(-1);
              }
            }}
          >
            <i className="bi bi-x fs-1 my-auto"></i>
          </span>
        </div>
        {/* NAVIGAZIONE */}
        <TabContext value={valueTop}>
          <div className="card-header bg-light d-flex">
            <TabList
              variant="scrollable"
              scrollButtons="auto"
              onChange={handleChangeValueTop}
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Tab
                icon={
                  <>
                    <i className="bi bi-clipboard-data fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      DETTAGLI
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={1}
              ></Tab>
              <Tab
                icon={
                  <>
                    <i className="bi bi-file-earmark-pdf fs-4"></i>
                    <span className="fs-4 text-wrap bg-light text-decoration-none pe-auto">
                      DOCUMENTI
                    </span>
                  </>
                }
                className="row justify-content-center"
                value={2}
              ></Tab>
            </TabList>
          </div>
          <div className="card-body">
            <TabPanel value={1} className="row flex-wrap">
              <div className="col-md-8 col-12 p-0">
                <div className=" row">
                  <CFormFloating className="mb-3 col-md-6">
                    <CFormInput
                      type="text"
                      id="MatricolaInput"
                      name="matricola"
                      defaultValue={personale.matricola}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).report
                          .allowEdit === 0
                          ? true
                          : location.state.operazione !== "Nuovo"
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>
                      Matricola
                    </CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    {searchReady ? (
                      <Autocomplete
                        value={mansioniSel}
                        onChange={(event, newValue) => {
                          let tipo = { ...personale };
                          tipo.mansione = newValue?.id;
                          setMansioniSel(newValue);
                          setPersonale(tipo);
                        }}
                        id="controllable-states-demo"
                        options={mansioni}
                        getOptionLabel={(option) => `${option.descrizione}`}
                        renderInput={(params) => (
                          <TextField {...params} label="Mansione" />
                        )}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).report
                            .allowEdit === 0
                        }
                      />
                    ) : (
                      ""
                    )}
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      name="nome"
                      defaultValue={personale.nome}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).report
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Nome</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      name="cognome"
                      defaultValue={personale.cognome}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).report
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Cognome</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <CFormInput
                      type="text"
                      name="luogo"
                      defaultValue={personale.luogo}
                      onChange={handleChange}
                      disabled={
                        JSON.parse(localStorage.getItem("permission")).report
                          .allowEdit === 0
                          ? true
                          : false
                      }
                    />
                    <CFormLabel style={{ left: "0.75rem" }}>Luogo</CFormLabel>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      locale='it'
                    >
                      <DatePicker
                        label="Data Nascita"
                        value={moment(personale.data_nascita)}
                        renderInput={(params) => (
                          <TextField {...params} className="col-12" />
                        )}
                        onChange={(newValue) => {
                          let nol = { ...personale };
                          nol["data_nascita"] = moment(newValue)
                            .local()
                            .format("YYYY-MM-DD");
                          setPersonale(nol);
                        }}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).report
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                    </LocalizationProvider>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      locale='it'
                    >
                      <DatePicker
                        label="Data Inizio"
                        value={moment(personale.data_inizio)}
                        renderInput={(params) => (
                          <TextField {...params} className="col-12" />
                        )}
                        onChange={(newValue) => {
                          let nol = { ...personale };
                          nol["data_inizio"] = moment(newValue)
                            .local()
                            .format("YYYY-MM-DD");
                          setPersonale(nol);
                        }}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).report
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                    </LocalizationProvider>
                  </CFormFloating>
                  <CFormFloating className="mb-3 col-md-6 col-12">
                    <LocalizationProvider
                      dateAdapter={AdapterMoment}
                      locale='it'
                    >
                      <DatePicker
                        label="Data Fine"
                        value={moment(personale.data_fine)}
                        renderInput={(params) => (
                          <TextField {...params} className="col-12" />
                        )}
                        onChange={(newValue) => {
                          let nol = { ...personale };
                          nol["data_fine"] = moment(newValue)
                            .local()
                            .format("YYYY-MM-DD");
                          setPersonale(nol);
                        }}
                        disabled={
                          JSON.parse(localStorage.getItem("permission")).report
                            .allowEdit === 0
                            ? true
                            : false
                        }
                      />
                    </LocalizationProvider>
                  </CFormFloating>
                </div>
              </div>
              <div className="d-flex row flex-wrap mb-3 col-md-4 col-12 px-0 bg-cover bg-center rounded-lg ">
                {personale.foto ? (
                  <>
                    <div
                      className="px-0 macchinari-image"
                      style={{
                        background: `url(${
                          personale.foto
                            ? "https://portale.fondamentasrl.it/file?file=" +
                              personale.foto?.split("/")[2]
                            : ""
                        }) no-repeat center / contain transparent`,
                      }}
                    ></div>
                  </>
                ) : (
                  <>
                    <div className="d-none mb-3">
                      <input
                        accept="image/jpeg"
                        className="form-control"
                        type="file"
                        id="formFile"
                        onChange={(e) => {
                          loadFileProfile(
                            e.target.files[0],
                            personale.matricola
                          );
                        }}
                      />
                    </div>
                    <div
                      className="px-0 macchinari-image mb-2"
                      style={{
                        background: `url(https://portale.fondamentasrl.it/profile.jpg) no-repeat center / contain transparent`,
                      }}
                    ></div>
                    <div className="d-flex justify-content-center">
                      <ReusableButton
                        text="CARICA FOTO"
                        icon="upload"
                        size="xxs"
                        onClick={(e) => {
                          document.getElementById("formFile").click();
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </TabPanel>
            <TabPanel value={2} className="p-0">
              <div className="p-3" style={{ height: 500, width: "100%" }}>
                <DataGridPro
                  components={{ Toolbar: CustomToolbar }}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  rows={[
                    {
                      id: "ci",
                      descrizione: "Carta di Identità",
                      file: personale.ci,
                      scadenza: personale.scadenza_ci,
                    },
                    {
                      id: "psog",
                      descrizione: "Permesso di Soggiorno",
                      file: personale.psog,
                      scadenza: personale.scadenza_psog,
                    },
                    {
                      id: "cf",
                      descrizione: "Codice Fiscale",
                      file: personale.cf,
                      scadenza: personale.scadenza_cf,
                    },
                    {
                      id: "ts",
                      descrizione: "Tessera Sanitaria",
                      file: personale.ts,
                      scadenza: personale.scadenza_ts,
                    },
                    {
                      id: "patente",
                      descrizione: "Patente",
                      file: personale.patente,
                      scadenza: personale.scadenza_patente,
                    },
                    {
                      id: "unilav",
                      descrizione: "Unilav",
                      file: personale.unilav,
                      scadenza: personale.scadenza_unilav,
                    },
                    {
                      id: "idoneita",
                      descrizione: "Idoneita",
                      file: personale.idoneita,
                      scadenza: personale.scadenza_idoneita,
                    },
                    {
                      id: "prescrizione",
                      descrizione: "Prescrizione",
                      file: personale.prescrizione,
                      scadenza: null,
                    },
                    {
                      id: "limitazione",
                      descrizione: "Limitazione",
                      file: personale.limitazione,
                      scadenza: null,
                    },
                    {
                      id: "formazione",
                      descrizione: "Corso Base",
                      file: personale.formazione,
                      scadenza: personale.scadenza_formazione,
                    },
                    {
                      id: "preposto",
                      descrizione: "Corso Preposto",
                      file: personale.preposto,
                      scadenza: personale.scadenza_preposto,
                    },
                    {
                      id: "dirigente",
                      descrizione: "Corso Dirigente",
                      file: personale.dirigente,
                      scadenza: personale.scadenza_dirigente,
                    },
                    {
                      id: "rspp",
                      descrizione: "Corso RSPP",
                      file: personale.rspp,
                      scadenza: personale.scadenza_rspp,
                    },
                    {
                      id: "rls",
                      descrizione: "Corso RLS",
                      file: personale.rls,
                      scadenza: personale.scadenza_rls,
                    },
                    {
                      id: "diisocianati",
                      descrizione: "Corso Diisocianati",
                      file: personale.diisocianati,
                      scadenza: personale.scadenza_diisocianati,
                    },
                    {
                      id: "ps",
                      descrizione: "Primo Soccorso",
                      file: personale.ps,
                      scadenza: personale.scadenza_ps,
                    },
                    {
                      id: "antincendio",
                      descrizione: "Antincendio",
                      file: personale.antincendio,
                      scadenza: personale.scadenza_antincendio,
                    },
                    {
                      id: "dpi3cat",
                      descrizione: "DPI 3^ CAT",
                      file: personale.dpi3cat,
                      scadenza: personale.scadenza_dpi3cat,
                    },
                    {
                      id: "saldatore",
                      descrizione: "Saldatore",
                      file: personale.saldatore,
                      scadenza: personale.scadenza_saldatore,
                    },
                    {
                      id: "ponteggi",
                      descrizione: "Ponteggi",
                      file: personale.ponteggi,
                      scadenza: personale.scadenza_ponteggi,
                    },
                    {
                      id: "quota",
                      descrizione: "Lavori in Quota",
                      file: personale.quota,
                      scadenza: personale.scadenza_quota,
                    },
                    {
                      id: "ambconf",
                      descrizione: "Confinanti",
                      file: personale.ambconf,
                      scadenza: personale.scadenza_ambconf,
                    },
                    {
                      id: "dumper",
                      descrizione: "Dumper",
                      file: personale.dumper,
                      scadenza: personale.scadenza_dumper,
                    },
                    {
                      id: "pfr_pd",
                      descrizione: "Perforatore Piccolo Diametro",
                      file: personale.pfr_pd,
                      scadenza: personale.scadenza_pfr_pd,
                    },
                    {
                      id: "pfr_gd",
                      descrizione: "Perforatore Grande Diametro",
                      file: personale.pfr_gd,
                      scadenza: personale.scadenza_pfr_gd,
                    },
                    {
                      id: "ple",
                      descrizione: "PLE",
                      file: personale.ple,
                      scadenza: personale.scadenza_ple,
                    },
                    {
                      id: "carroponte",
                      descrizione: "Carroponte",
                      file: personale.carroponte,
                      scadenza: personale.scadenza_carroponte,
                    },
                    {
                      id: "pompecls",
                      descrizione: "Pompe CLS",
                      file: personale.pompecls,
                      scadenza: personale.scadenza_pompecls,
                    },
                    {
                      id: "grutorre",
                      descrizione: "Gru a Torre",
                      file: personale.grutorre,
                      scadenza: personale.scadenza_grutorre,
                    },
                    {
                      id: "elev",
                      descrizione: "Carrelli Elevatori",
                      file: personale.elev,
                      scadenza: personale.scadenza_elev,
                    },
                    {
                      id: "autogru",
                      descrizione: "Autogru",
                      file: personale.autogru,
                      scadenza: personale.scadenza_autogru,
                    },
                    {
                      id: "mmt",
                      descrizione: "Macchine Movimento Terra",
                      file: personale.mmt,
                      scadenza: personale.scadenza_mmt,
                    },
                    {
                      id: "altro1",
                      descrizione: "Altro",
                      file: personale.altro1,
                      scadenza: personale.scadenza_altro1,
                    },
                    {
                      id: "altro2",
                      descrizione: "Altro",
                      file: personale.altro2,
                      scadenza: personale.scadenza_altro2,
                    },
                  ]}
                  columns={columnsDoc}
                  rowHeight={80}
                  getRowId={(row) => row.id}
                  pageSize={10}
                  disableSelectionOnClick
                />
              </div>
            </TabPanel>
          </div>
        </TabContext>
        <div className="d-none mb-3">
          <input
            className="form-control"
            type="file"
            id="formFile"
            onChange={(e) => {
              loadFile(
                e.target.files[0],
                personale.matricola,
                valueUpload,
                colonnaDb
              );
            }}
          />
        </div>
        {/* FOOTER */}
        <div className="card-footer d-flex justify-content-between ">
          <div className="gap-2 d-flex justify-content-start flex-md-nowrap flex-wrap">
            <ReusableButton
              text="STAMPA"
              icon="printer"
              size="xxs"
              className={`${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
              onClick={() => {
                navigate(`/home/personale/stampa/${personale.matricola}`, {
                  state: {
                    personale: personale,
                    stampa: "PersonaleDettaglio",
                  },
                });
              }}
            />
            <ReusableButton
              text="QR CODE"
              icon="qr-code"
              className={`${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
              size="xxs"
              onClick={() => {
                let arr = [];
                arr.push(personale);
                navigate(`/home/personale/print-qrcode`, {
                  state: { personale: arr },
                });
              }}
            />
            <ReusableButton
              text="ARCHIVIO"
              icon="archive"
              size="xxs"
              onClick={() => {
                return window.open(
                  "https://portale.dscloud.me:7036/index.cgi?launchApp=SYNO.SDS.App.FileStation3.Instance&launchParam=openfile%3D%252FPORTALE%252F" +
                    "PERSONALE/" +
                    personale.matricola +
                    "_" +
                    personale.cognome
                      .split(" ")
                      .join("")
                      .toUpperCase()
                      .slice(0, 10) +
                    "_" +
                    personale.nome
                      .split(" ")
                      .join("")
                      .toUpperCase()
                      .slice(0, 10) +
                    "%252F#/signin"
                );
              }}
            />
            <ReusableButton
              className={`${
                location.state.operazione === "Nuovo" ? "d-none" : "d-block"
              }`}
              text="ESPORTA"
              icon="cloud-download"
              size="xxs"
              onClick={() => zipAllFiles()}
            />
          </div>

          <div className="d-flex justify-content-end gap-2 flex-md-nowrap flex-wrap">
            <ReusableButton
              text="ELIMINA"
              icon="trash"
              size="xs"
              className={`bg-red ${
                permission.report.allowDelete === 0
                  ? "d-none"
                  : location.state.operazione === "Nuovo"
                  ? "d-none"
                  : "d-flex"
              }`}
              onClick={() => handleDelete()}
            />
            <ReusableButton
              text="SALVA"
              icon="check"
              size="xs"
              onClick={() => modifyPersonale()}
              style={{
                display:
                  JSON.parse(localStorage.getItem("permission")).report
                    .allowEdit === 0
                    ? "none"
                    : "flex",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DettaglioPersonale;
